<template>
    <section>
        <b-card>
            <span class="text-secondary">{{ item.no }}</span>
            <h3 class="mb-2">Detail Pengembalian</h3>
            <b-row>
                <b-col sm="6" md="4" lg="3">
                    <b-form-group label="Sales">
                        <strong>{{ item.sales ? item.sales.nama_lengkap : item.id_sales }}</strong>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="4" lg="3">
                    <b-form-group label="Tanggal Request Pengembalian">
                        <strong>{{ humanDate(item.tanggal) }}</strong>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="4" lg="3">
                    <b-form-group label="Keterangan">
                        <strong>{{ item.keterangan }}</strong>
                    </b-form-group>
                </b-col>
                <b-col sm="6" md="4" lg="3">
                    <b-form-group label="Tujuan Pengembalian">
                        <strong>{{ item.gudang ? item.gudang.nama_gudang : item.id_gudang }}</strong>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <h3>Rincian Barang <span v-if="item.rincian && item.rincian.length > 0">({{ item.rincian.length }})</span></h3>
        <b-card>
            <b-table responsive striped bordered :fields="fields" :items="item.rincian">
                <template #cell(no)="{index}">{{++index}}</template>
                <template #cell(nama)="{item}">
                    <span v-if="item.barang"> {{ item.barang.nama }} </span>
                    <strong v-else>
                        <i class="text-danger">Barang tidak ditemukan</i>
                    </strong>
                </template>
                <template #cell(status)="{item}">
                    <feather-icon size="24" v-if="item.status && item.status.approve" icon="CheckCircleIcon" class="text-success"></feather-icon>
                    <feather-icon size="24" v-else icon="XCircleIcon" class="text-danger"></feather-icon>
                </template>
                <template #cell(satuan)="{item}">
                    <span v-if="item.barang"> {{ item.barang.satuan ? item.barang.satuan.satuan : '-' }} </span>
                    <strong v-else>
                        <i class="text-danger">Barang tidak ditemukan</i>
                    </strong>
                </template>
                <template #cell(action)="{index, item, toggleDetails}">
                  
                    <feather-icon v-if="isSales && myGudang.id" icon="XCircleIcon" size="24" class="text-danger" @click="removeRincian(item, index)"></feather-icon>
                    <b-button v-else-if="isAdminGudang && !isSales && myGudang.id" variant="primary" @click.prevent="toggleDetails" size="sm">Pilih Stok</b-button>
                    <feather-icon v-else icon="SlashIcon" size="24" class="text-secondary"></feather-icon>
                </template>
                <template #row-details="{item, toggleDetails}">
                  <b-card>
                          <b-row class="mb-2">
                              <b-col cols="12">
                                  <table>
                                      <tr>
                                          <th>No</th>
                                          <th>Gudang</th>
                                          <th>Blok</th>
                                          <th>Pallet</th>
                                          <th>Rak</th>
                                          <th>Laci</th>
                                          <th>Stok</th>
                                          <th>Stok Gudang</th>
                                      </tr>
                                      <tr :key="i" v-for="(stock, i) in item.stocks">
                                          <td>{{(i+1)}}</td>
                                          <td>
                                              {{ stock.gudang ? stock.gudang.nama_gudang : '-' }}
                                          </td>
                                          <td>{{ stock.blok ? `${stock.blok.blok}` : '-' }}</td>
                                          <td>{{ stock.palet ? `${stock.palet.palet}` : '-' }}</td>
                                          <td>{{ stock.rak ? `${stock.rak.rak}` : '-' }}</td>
                                          <td>{{ stock.laci ? `${stock.laci.laci}` : '-' }}</td>
                                          <td>{{ stock.stok }}</td>
                                          <td>
                                            <validation-provider #default="{errors}" :rules="`max_value:${item.qty}`">
  
                                              <b-form-input :state="errors.length > 0 ? false : null" v-model="stock.requestedStock"></b-form-input>
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                          </td>
                                      </tr>
                                  </table>
                              </b-col>
                          </b-row>
  
                          <b-button
                          size="sm"
                          variant="outline-secondary"
                          @click="toggleDetails"
                          >
                          Hide
                          </b-button>
                      </b-card>
                </template>
            </b-table>
        </b-card>
        <div class="d-flex justify-content-end" v-if="isAdminGudang && !isSales">
          <b-button variant="secondary" v-if="isApproved" disabled>Sudah Disetujui</b-button>
          <b-button variant="primary" v-else @click.prevent="submit">Setujui Pengembalian</b-button>
        </div>
    </section>
  </template>
  <script>
  import {BCard, BRow, BCol, BFormGroup, BTable, BButton, BFormInput} from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, email, confirmed, password } from "@validations";
  export default {
    data: () => ({
        item: {},
        fields: [
            {key: 'no', label: 'No'},
            {key: 'nama', label: 'Barang'},
            {key: 'qty', label: 'Stok Pengembalian'},
            {key: 'satuan', label: 'Satuan'},
            {key: 'status', label: 'Disetujui'},
            {key: 'action', label: ''}
        ],
        confirmOption: {
            title: 'Anda yakin?',
            text: `Rincian barang ini akan dihapus`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false
        },
        approveOption: {
          title: 'Anda yakin?',
          text: `Request Stok akan dikembalikan ke Gudang Anda`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false
        }
    }),
    methods: {
      submit() {
          this.$swal(this.approveOption)
          .then(async res => {
              if(res.value) {
                  const payloads = []
                  this.item.rincian.map(rincian => {
                      const findStock = rincian.stocks.find(stock => stock.requestedStock > 0)
                      const penyimpananId = rincian.stocks && findStock ? findStock.id : null
                      const payload = {
                          id_pengembalian_rincian: rincian.id,
                          id_gudang: this.myGudang.id,
                          id_penyimpanan: penyimpananId,
                          jumlah: findStock ? findStock.requestedStock : 0
                      }
  
                      payloads.push(payload)
                  })
                  this.loading = true
                  await this.$store.dispatch('pengembalianbarang/approve', payloads)
                  await this.getData()
                  this.displaySuccess({
                      message: 'Harap pastikan stok barang di gudang anda bertambah'
                  })
                  this.loading = false
              }
          })
      },
        removeRincian(item, index) {
            this.$swal(this.confirmOption)
            .then(async res => {
                if(res.value) {
                    await this.$store.dispatch('pengembalianbarang/saveRincian', [{
                        id: item.id,
                        fungsi: 1
                    }])
  
                    // remove from array
                    this.item.rincian.splice(index, 1)
                }
            })
        },
        async getStocks(rincians) {
          const barangsId = rincians.map(rincian => rincian.id_barang)
  
          const stocks = await this.$store.dispatch('penyimpanan/getData', {
              barang_id: barangsId.join(','),
              gudang_id: this.myGudang.id
          })
  
          rincians.map(rincian => {
              rincian.stocks = stocks.filter(stock => stock.barang && stock.barang.id == rincian.id_barang)
              rincian.stocks.map(stock => {
                  stock.requestedStock = 0
                  if(rincian.approve) {
                      stock.requestedStock = rincian.approve.jumlah
                  }
              })
          })
  
          return rincians
        },
        async getData() {
          const items = this.$store.state.pengembalianbarang.datas
          const pengembalian = items.find(item => item.id == this.$route.params.id)
          if(!pengembalian) {
            this.$router.push('/pengembalian')
          }
          // get penyimpanan barang
          pengembalian.rincian = await this.getStocks(pengembalian.rincian)
          this.item = pengembalian
        }
    },
    computed: {
      isApproved() {
          return this.item.rincian && this.item.rincian.every(rincian => rincian.status && rincian.status.approve)
      }
    },
    components: {
        BCard, BRow, BCol, BFormGroup, BTable, BButton, BFormInput, ValidationProvider, ValidationObserver
    },
    created() {
      this.getData()
    }
  }
  </script>